@import './colors.scss';

.error-modal-container {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 500vh;
  background-color: rgba(50, 50, 50, 0.3);
  overflow: visible;
}

.error-modal-container-invisible {
  display: none;
}

.error-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border: solid 16px rgba(220, 220, 220, 0.85);
  min-height: 220px;
  max-width: 650px;
  margin: 100px auto;
  padding: 0 0 1% 0;

  .error-dialog-top-row {
    border-top: 2px $orange-border-top solid;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .error-dialog-middle-row {
    margin: 0 2%;

    .alert-icon {
      margin-right: 2%;
    }

    .error-middle-inner-top-row {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-left: 1%;
      margin-bottom: 2%;
    }

    .error-middle-inner-bottom-row {
      margin-left: 9%;
    }
  }

  .x-close {
    margin-right: 2%;
    margin-top: 1%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    overflow: hidden;
    width: 30px;
    height: 30px;
  }

  .modal-bottom-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-top: 4%;
    margin-right: 2%;
    .close-btn {
      background-color: $primary-petrol-blue;
      color: $white;
      font-size: 16px;
      line-height: 140%;
      height: 31px;
      width: 60px;
      border-radius: 3px;
      padding: 4px 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 2%;
    }
  }
}
