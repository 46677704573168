@import './colors.scss';
.main-app-container {
  width: 75%;
  margin: 3% auto;
}

.jobs-list {
  margin-top: 5%;
  width: 100%;
  p {
    margin: 2% auto;
  }

  h2 {
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  .no-reports-message {
    margin: 2% auto;
    font-size: 16px;
  }
}

.collection-table {
  margin-top: 1%;
  overflow: visible;

  thead th {
    position: sticky;
    top: 0;
    background: $white;
    border-bottom: 1px solid $black;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    font-weight: 700;
    padding: 2px 0px;
    border-top-color: transparent;
  }

  tbody td {
    border-top: 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    padding: 0.7% 0;
  }

  td.file-name,
  th.file-name {
    width: 45%;
  }

  td.request-date,
  th.request-date {
    width: 35%;
  }

  td.request-status,
  th.request-status {
    width: 12%;
  }
}
